import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Button, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { graphql, useStaticQuery } from 'gatsby';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import * as styles from './index.module.css';

// markup
const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
    <Layout
      pageTitle={data.site.siteMetadata.title}
    >     
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
      </Helmet>
      <section>
        <Typography className={styles.bodyText} variant='h6' sx={{ textAlign: 'center', marginTop: '10px' }}>
          I'm a software developer that enjoys trying out interesting technologies. <br />
          Also I'm frequently bored and always looking for new things.
        </Typography>
        <img
          src='https://www.gravatar.com/avatar/b885f790a72cd584cbbc9b4e9e050948.png?s=512'
          alt='Gravatar Profile'
          className={styles.avatar}
        />
      </section>
      <section style={{
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)'
      }}>
        <Typography className={styles.bodyText} variant='h5' sx={{ textAlign: 'center', marginTop: '25px' }}>
          Find me on
        </Typography>
        <Toolbar>
          <Box display='flex' flexGrow={0.5}></Box>
          <Button startIcon={<GitHubIcon />} href='https://github.com/ruifung/'>GitHub</Button>
          <Button startIcon={<LinkedInIcon />} href='https://www.linkedin.com/in/yipruifung/'>LinkedIn</Button>
          <Box display='flex' flexGrow={0.5}></Box>
        </Toolbar>
      </section>
    </Layout >
  )
}

export default IndexPage
